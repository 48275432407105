// detecting user device or browser language automatically
// If the browser and device language is arabic, then return ar. else, return en.

export function detectUserLanguage(): string {
    const language = navigator.language; ;

    // Check if the language is Arabic (ar)
    if (language.startsWith('ar')) {
        return 'ar';
    }

    // Default to English (en)
    return 'en';
}
