import { DigitalTypes } from "src/types/temps";

export const paths = {
  home: {
    index: "/",
    newsletter: `/newsletter`,
  },
  legal: {
    policy: "/privacyPolicy",
    termsOfService: "/termsOfService",
  },
  auth: {
    login: "/auth/login",
    register: "/auth/register",
    verify: (email: string) => `/auth/verify?email=${email}`,
    forget: `/auth/forgetPassword`,
  },
  admin: {
    login: "/admin/login",
    verify: (email: string) => `/admin/verify?email=${email}`,
  },
  dashboard: {
    admin: {
      home: "/dashboard",
      business_title: "/dashboard/titles",
      templates: "/dashboard/templates",
      all_templates: "/dashboard/allTemplates",
      edit_template: (id: string) => `/dashboard/templates/${id}`,
      business_cards: "/dashboard/customerCards",
      browse_customers: "/dashboard/browseCustomers",
    },
    customer: {
      home: "/customer",
      register: "/auth/register",
      digitalHub: "/customer/create",
      edit_digital: (id: string, type: DigitalTypes, templateId?: string) =>
        `/customer/edit/${id}${
          templateId ? `/${templateId}` : ""
        }?type=${type}`,
      account: "/customer/Account",
      contact: (cardId: string, cardHolder: string) =>
        `/customer/contact/${cardId}/${cardHolder}`,
    },
  },
};
