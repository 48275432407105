import {
  alpha,
  Avatar,
  Box,
  Button,
  Icon,
  IconButton,
  ListItemIcon,
  MenuItem,
  Switch,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useEffect, useState, useCallback, useRef } from "react";
import {
  Actions,
  Props,
  RowStyle,
  collapses,
  expandIcon,
  topToolbarProps,
} from "./props";
import {
  MaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_Row,
  MRT_ShowHideColumnsButton,
  MRT_TableInstance,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFullScreenButton,
  type MRT_ColumnDef,
} from "material-react-table";
import Carousel from "react-material-ui-carousel";
import Iconify from "../iconify/Iconify";
import { useTranslate } from "src/locales";

type TableProps = {
  dataSchema: Props;
  data: any[];
  total?: number;
  pageSize?: number;
  setPageSize?: (p: number) => void;
  page?: number;
  setPage?: (event: unknown, newPage: number) => void;
  order?: "asc" | "desc";
  orderBy?: string;
  checkBoxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  CustomizeHeader?: { [key: string]: React.ReactElement };
  renderCell?: { [key: string]: (jsn: any) => React.ReactNode };
  actions?: Actions[];
  allowsFilter?: string[];
  allowsOrder?: string[];
  hiddenColumns?: string[];
  columnsVisibility?: Record<string, boolean>;
  onUpdateFilter?: (column: string, operation: string, value: any) => void;
  OnUpdateOrder?: (orderBy: string) => void;
  collapses?: collapses[];
  topToolbar?: topToolbarProps[];
  iconExpand?: expandIcon;
  CustomizeRowStyle?: RowStyle[];
  rowPerPage?: number[];
  loading?: boolean;
  error?: any;
  tableStyle?: SxProps<Theme>;
  disableDetail?: boolean;
};
const Table = (props: TableProps) => {
  // type of the colomns and data props of data grid
  const { t } = useTranslate();

  const [columns, setColumns] = useState<MRT_ColumnDef<any>[]>([]);
  const {
    dataSchema,
    data,
    total = data.length,
    pageSize = Math.min(data.length, 3),
    setPageSize,
    page = 1,
    setPage,
    order = "asc",
    orderBy,
    actions = [],
    topToolbar = [],
    checkBoxSelection = true,
    disableRowSelectionOnClick = false,
    CustomizeHeader,
    renderCell,
    allowsOrder = [],
    allowsFilter = [],
    onUpdateFilter,
    OnUpdateOrder,
    collapses = [],
    iconExpand,
    CustomizeRowStyle = [],
    rowPerPage = [3, 5, 6],
    hiddenColumns = [],
    columnsVisibility = {},
    loading = false,
    error,
    tableStyle,
    disableDetail = false,
    ...other
  } = props;

  const tableComponent = useRef(null);
  //create the columns
  useEffect(() => {
    if (dataSchema && Object.keys(dataSchema).length > 0) {
      const newColumns: MRT_ColumnDef<any>[] = [];
      Object.keys(dataSchema)
        .filter((key) => !hiddenColumns.includes(key))
        .map((key) => {
          const column: MRT_ColumnDef<any> = {
            accessorKey: key,
            header: dataSchema[key].label || key,
            size: 1,
            filterFn: "fuzzy",
            ...(dataSchema[key].filterable !== undefined
              ? {
                  enableColumnFilter: dataSchema[key].filterable,
                  enableColumnActions: dataSchema[key].filterable,
                  enableSorting: dataSchema[key].filterable,
                }
              : {
                  enableColumnFilter: true,
                  enableColumnActions: true,
                  enableSorting: true,
                }),
          };
          column.Header = ({ column }) => {
            return (
              <Box display="flex">
                {CustomizeHeader && CustomizeHeader[key]}
                {(!CustomizeHeader || !CustomizeHeader[key]) && (
                  <Box display="flex" alignItems="center">
                    {dataSchema[key].icon && (
                      <Icon>{dataSchema[key].icon}</Icon>
                    )}
                    {dataSchema[key].label}
                  </Box>
                )}
                {allowsFilter.includes(key) && (
                  <IconButton
                    key={`${key}-filter`}
                    size="small"
                    color="primary"
                    onClick={() =>
                      onUpdateFilter &&
                      onUpdateFilter(key, "operation", "value")
                    }
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                )}
                {allowsOrder.includes(key) && (
                  <IconButton
                    key={`${key}-order`}
                    size="small"
                    color="primary"
                    onClick={() => OnUpdateOrder && OnUpdateOrder(key)}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Icon>
                      {orderBy === key && order === "asc"
                        ? "south_icon"
                        : "north_icon"}
                    </Icon>
                  </IconButton>
                )}
              </Box>
            );
          };
          if (renderCell) {
            const cellRenderers = Object.entries(renderCell);
            for (const [columnKey, cellRenderer] of cellRenderers) {
              if (key == columnKey) {
                column.Cell = ({
                  cell: {
                    row: { original },
                  },
                }) => {
                  return cellRenderer?.(original);
                };
              }
            }
          }
          if (dataSchema[key].type === "image") {
            if (dataSchema[key]?.multiple) {
              column.Cell = ({ cell }) => {
                const images = cell.getValue() as string[];
                return (
                  <Box>
                    <Carousel
                      navButtonsProps={{
                        style: { padding: "0px", opacity: 0.6 },
                      }}
                      navButtonsAlwaysVisible
                      autoPlay={false}
                      cycleNavigation={false}
                      indicators={false}
                    >
                      {images?.map((item, index) => (
                        <img
                          key={index}
                          src={item}
                          style={{
                            width: "100%",
                            height: "70px",
                            borderRadius: "10px",
                            objectFit: "cover",
                          }}
                        />
                      ))}
                    </Carousel>
                  </Box>
                );
              };
            } else {
              column.Cell = ({
                cell: {
                  row: { original },
                },
              }) => <Avatar src={original[key] as string} />;
            }
          }
          if (dataSchema[key].type === "date") {
            column.accessorFn = (row) => new Date(row[key]);
            column.Cell = ({
              cell: {
                row: { original },
              },
            }) => {
              return original[key]
                ? new Date(original[key]).toLocaleDateString()
                : null;
            };
            column.filterVariant = "date-range";
            column.filterFn = "between";
            column.sortingFn = "datetime";
          }
          if (dataSchema[key].type === "boolean") {
            column.Cell = ({
              cell: {
                row: { original },
              },
            }) => {
              return original ? (
                <Switch checked={original[key]} disabled />
              ) : null;
            };
          }
          if (dataSchema[key].type === "object") {
            column.Cell = ({
              cell: {
                row: { original },
              },
            }) => {
              return original[key] ? JSON.stringify(original[key]) : "";
            };
          }
          if (dataSchema[key].type === "array") {
            column.Cell = ({
              cell: {
                row: { original },
              },
            }) => {
              return original[key] ? JSON.stringify(original[key]) : "";
            };
          }

          if (renderCell) {
            const cellRenderers = Object.entries(renderCell);
            for (const [columnKey, cellRenderer] of cellRenderers) {
              if (key == columnKey) {
                column.Cell = ({
                  cell: {
                    row: { original },
                  },
                }) => {
                  return cellRenderer?.(original);
                };
              }
            }
          }

          newColumns.push(column);
        });
      setColumns(newColumns);
    }
  }, [dataSchema, data]);

  const renderRowActions = useCallback(
    ({ row }: { row: MRT_Row<any> }) => {
      if (actions.length > 0) {
        return (
          <Box style={{ display: "flex" }}>
            {actions.map((act) => (
              <IconButton
                key={act.label}
                color={act.color}
                onClick={() => act.fct(row.original)}
                disabled={act?.cond && !act.cond(row.original)}
              >
                <Icon>{act.icon}</Icon>
              </IconButton>
            ))}
          </Box>
        );
      }
      return null;
    },
    [actions]
  );

  const renderRowActionMenuItems = useCallback(
    ({ row, closeMenu }: { row: MRT_Row<any>; closeMenu: VoidFunction }) => {
      return actions.map((act) => (
        <MenuItem
          key={act.label}
          onClick={() => {
            act.fct(row.original);
            closeMenu();
          }}
          disabled={act?.cond && !act.cond(row.original)}
        >
          <ListItemIcon>
            <Iconify
              icon={act.icon}
              color={
                act.color.includes("main") ? act.color : `${act.color}.main`
              }
            />
          </ListItemIcon>
          {act.label}
        </MenuItem>
      ));
    },
    [actions]
  );

  const renderDetailPanel = useCallback(
    ({ row }: { row: MRT_Row<any> }) => {
      if (collapses.length === 0) {
        return <Typography>Aucun détail</Typography>;
      }
      return collapses
        .filter((collapse) =>
          collapse.cond ? collapse.cond(row.original) : true
        )
        .map((collapse, index) => (
          <div key={index}>{collapse.render(row)}</div>
        ));
    },
    [collapses]
  );

  const renderTopToolbar = useCallback(
    ({ table }: { table: MRT_TableInstance<any> }) => {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: "0.5rem",
            p: "8px",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
              {topToolbar
                .filter((item) =>
                  item?.cond
                    ? item?.cond(
                        table
                          .getSelectedRowModel()
                          .flatRows.map((row) => row.original)[0]
                      )
                    : true
                )
                .map((item, index) => {
                  return (
                    <Button
                      key={index}
                      variant="contained"
                      color={item?.color}
                      // disabled={table.getIsAllPageRowsSelected() ? false : !table.getIsSomeRowsSelected()}
                      onClick={() =>
                        item.fct?.(
                          table
                            .getSelectedRowModel()
                            .flatRows.map((row) => row.original)
                        )
                      }
                      endIcon={<Icon>{item?.icon}</Icon>}
                      sx={{
                        display:
                          table.getIsSomeRowsSelected() ||
                          table.getIsAllPageRowsSelected()
                            ? "flex"
                            : "none",
                      }}
                    >
                      {item.label}
                    </Button>
                  );
                })}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
            <MRT_GlobalFilterTextField table={table} />
            {/* <MRT_ToggleFiltersButton table={table} /> */}
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>
        </Box>
      );
    },
    []
  );

  const styleRowBody = useCallback(
    ({ row }: { row: MRT_Row<any> }) => {
      let rowStyle = {};
      if (row.original) {
        for (const item of CustomizeRowStyle) {
          if (item?.cond(row.original, Number(row.original.id))) {
            rowStyle = { ...rowStyle, ...item?.style };
          }
        }
      }
      return { sx: rowStyle };
    },
    [CustomizeRowStyle]
  );

  const styleExpandIcon = useCallback(
    ({ row, table }: { row: MRT_Row<any>; table: MRT_TableInstance<any> }) => {
      let expandStyle: React.CSSProperties = {};
      let expandIcon: React.ReactNode = null;
      if (iconExpand) {
        if (iconExpand?.cond ? iconExpand?.cond(row.original) : true) {
          expandStyle = { ...expandStyle, display: "flex" };
          if (iconExpand?.renderCell) {
            expandIcon = iconExpand.renderCell();
          }
        } else {
          expandStyle = { ...expandStyle, display: "none" };
        }
      }
      return {
        sx: expandStyle,
        children: expandIcon,
        onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }),
      };
    },
    [iconExpand]
  );

  const displayTitleOfCollapse = iconExpand
    ? {
        "mrt-row-expand": {
          header: iconExpand.title,
        },
      }
    : null;
  return (
    <Box
      ref={tableComponent}
      sx={{
        ...(tableStyle ?? {}),
      }}
    >
      <MaterialReactTable
        columns={columns}
        data={data}
        enableTopToolbar={true}
        paginateExpandedRows={false}
        enableColumnFilterModes={false}
        columnFilterDisplayMode="popover"
        enableColumnActions={true}
        enableSorting={true}
        enableRowSelection
        enableRowActions={actions.length > 0}
        positionExpandColumn="first"
        positionActionsColumn="first"
        // renderRowActions={renderRowActions}
        renderRowActionMenuItems={renderRowActionMenuItems}
        renderDetailPanel={disableDetail ? undefined : renderDetailPanel}
        muiTableBodyCellProps={styleRowBody}
        muiExpandButtonProps={styleExpandIcon}
        displayColumnDefOptions={{ ...displayTitleOfCollapse }}
        enableExpandAll={false}
        initialState={{
          showGlobalFilter: true,
          showColumnFilters: true,
          columnPinning: {
            left: ["mrt-row-select", "mrt-row-actions", "mrt-row-expand"],
            right: ["details"],
          },
          pagination: {
            pageSize: 3,
            pageIndex: 0,
          },
          columnVisibility: columnsVisibility,
        }}
        state={{
          isLoading: loading,
        }}
        muiPaginationProps={{
          color: "primary",
          shape: "circular",
          variant: "outlined",
          rowsPerPageOptions: [3, 5, 10, 20],
        }}
        paginationDisplayMode="pages"
        muiDetailPanelProps={{
          sx: {
            bgcolor: (theme) => alpha(theme.palette.text.disabled, 0.2),
          },
        }}
        muiTableHeadCellProps={{
          sx: {
            bgcolor: (theme) => theme.palette.background.neutral,
            color: (theme) => theme.palette.primary.dark,
            borderRight: (theme) => `1px solid ${theme.palette.primary.dark}`,
          },
        }}
        muiTableContainerProps={{
          sx: {
            "&::-webkit-scrollbar": {
              height: 8,
            },

            "&::-webkit-scrollbar-thumb": {
              bgcolor: "text.disabled",
              borderRadius: 1,
            },
          },
        }}
        renderTopToolbar={renderTopToolbar}
        localization={{
          rowsPerPage: t("infos.rowPerPage"),
          // filterByColumn: t("infos.search"),
          search: t("infos.search"),
          // sortByColumnAsc: "ترتيب تصاعدي",
          actions: t("infos.action"),
          noRecordsToDisplay: t("infos.noRecords"),
        }}
        {...other}
      />
    </Box>
  );
};

export default Table;

const baseWidth = (width: number) => {
  return width > 400 ? (width > 760 ? (width > 1024 ? 80 : 70) : 60) : 50;
};
const widthLevel = (width: number) => {
  return Math.floor(width / baseWidth(width));
};
