import { Button, IconButton, Stack, Typography } from "@mui/material";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContactModel, fetchContactType } from "src/api/contact/contact.model";
import Iconify from "src/components/iconify/Iconify";
import { useTranslate } from "src/locales";
import CustomerTable from "src/sections/admin/customer-management/customer-table";
import { ActiosProps, titleHeaderProps } from "src/types/table";

export default function CustomerContactView() {
  const { t } = useTranslate();

  const [contactList, setContactList] = useState<fetchContactType[] | null>(
    null
  );
  const [busyTable, setBusyTable] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { cardId = "" } = useParams();
  const { cardHolder = "" } = useParams();

  const model = new ContactModel();

  const actions: ActiosProps = [
    {
      label: t("common.delete.btn"),
      icon: "material-symbols:delete",
      color: "primary",
      fct: (data: fetchContactType) => {
        enqueueSnackbar(`${t("common.delete.contactWarning")} ${data?.name}`, {
          variant: "warning",
          anchorOrigin: { vertical: "top", horizontal: "center" },
          action: (
            <>
              <Button
                size="small"
                color="error"
                onClick={async () => {
                  try {
                    await model.deleteContact(data?.id); // Call the delete API
                    enqueueSnackbar("", {
                      variant: "success",
                    });
                    await getContact(); // Refresh the table
                  } catch (error) {
                    enqueueSnackbar(t("Failed to delete the contact"), {
                      variant: "error",
                    });
                  } finally {
                    closeSnackbar();
                  }
                }}
              >
                {t("common.delete.btn")}
              </Button>
              <IconButton
                size="small"
                onClick={() => closeSnackbar()}
                sx={{ p: 0.5 }}
              >
                <Iconify width={16} icon="mingcute:close-line" />
              </IconButton>
            </>
          ),
        });
      },
    },
  ];

  const getContact = useCallback(async () => {
    setContactList([]); // Clear existing data
    setBusyTable(true);
    setError(null);

    try {
      const contact: fetchContactType[] = await model.fetchContact(cardId);
      setContactList(contact);
    } catch (err) {
      setError(t("Unable to fetch contact data. Please try again."));
    } finally {
      setBusyTable(false);
    }
  }, [cardId, t]);

  useEffect(() => {
    getContact();
  }, [getContact]);

  const dataSchema: titleHeaderProps = {
    id: { label: "Contact ID", type: "string" },
    name: { label: t(`infos.guestName.label`), type: "string" },
    phone: { label: t(`infos.phone.label`), type: "string" },
    email: { label: t(`infos.email.label`), type: "string" },
    role: { label: t(`infos.businessTitle.shortLabel`), type: "string" },
    company: { label: t(`infos.companyName.shortLabel`), type: "string" },
    description: { label: t(`infos.note`), type: "string" },
    createdAt: { label: t(`infos.createdAt`), type: "date" },
  };

  return (
    <Stack maxWidth={"100%"} spacing={4} my={2}>
      <Typography variant="h4" gutterBottom>
        {t("dashboard.Account Dashboard.ContactManagement")} - {cardHolder}
      </Typography>
      <div>
        <CustomerTable<fetchContactType>
          data={contactList || []}
          dataSchema={dataSchema}
          count={contactList?.length || 0}
          loading={busyTable}
          error={error}
          columnsVisibility={
            {
              id: false,
            } as Record<keyof fetchContactType, boolean>
          }
          isDetailed={false}
          actions={actions}
          isCheckBox={false}
        />
      </div>
    </Stack>
  );
}
