import Stack from '@mui/material/Stack';

import NavList from './nav-list';
import { NavProps } from '../types';

// ----------------------------------------------------------------------

export default function NavDesktop({ data }: NavProps) {
  return (
    <Stack
      component="nav"
      direction="row"
      sx={{
        width: '100%', // Ensures the nav spans the full screen width
        height: '100%', // Optional: Adjusts the height to match the parent
        position: 'relative', // Optional: Useful if you want to make it sticky or fixed
        justifyContent: 'space-between', // Ensures the nav items are evenly spaced
        alignItems: 'center',
        px: { xs: 2, sm: 4, lg: 5 }, // Adds padding on the sides for better spacing
      }}
    >
      {data.map((list) => (
        <NavList key={list.title} data={list} />
      ))}
    </Stack>
  );
}

