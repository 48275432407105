import Iconify from "src/components/iconify/Iconify";

// ----------------------------------------------------------------------

export const navConfig = [
  {
    title: "Home",
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: "/#Home",
  },
  {
    title: "Features",
    icon: <Iconify icon="ic:outline-touch-app" />,
    path: "/#Features",
  },
  {
    title: "why",
    icon: <Iconify icon="pajamas:question" />,
    path: "/#whyWsam",
  },
  {
    title: "Benefits",
    icon: <Iconify icon="ph:slideshow-fill" />,
    path: "/#Benefits",
  },
  {
    title: "Products",
    icon: <Iconify icon="icomoon-free:price-tags" />,
    path: "https://shop.wsam.io/business_card/p425434238",
  },
  {
    title: "About us",
    icon: <Iconify icon="material-symbols:info" />,
    path: "/#About_us",
  },
  {
    title: "Contact us",
    icon: <Iconify icon="mdi:contact" />,
    path: "/#Contact_us",
  },
  {
    title: "Faqs",
    icon: <Iconify icon="wpf:faq" />,
    path: "/#faqs",
  },
];
